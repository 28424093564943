@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');


body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  color: #333;
  overflow-y: scroll;
}

.title {
  font-family: "Sofia Sans Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.title-bold {
  font-family: "Sofia Sans Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


/* Scrollbar */
.stylledScroll {
  scrollbar-width: thin;
  scrollbar-color: rgb(156, 156, 156) #f2f2f2;
}
.stylledScroll::-webkit-scrollbar, html .stylledScroll::-webkit-scrollbar {
  height: 12px;
  width: 7px;
}

.stylledScroll::-webkit-scrollbar-track, html .stylledScroll::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.stylledScroll::-webkit-scrollbar-thumb, html .stylledScroll::-webkit-scrollbar-thumb {
  background-color: rgb(156, 156, 156);
  border-radius: 5px;
  border: 3px solid rgb(156, 156, 156);
}